<template>
    <div>
        cancelado
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>